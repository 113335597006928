import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TopHeader from '../common/TopHeader';
import DeleteUser from './DeleteUser';
import { UPLOADCSV, WEBSITES, fetch_get, fetch_post, Pagination, CREATE_WEBSITE, CLIENT_WEBSITES, ORDER, WEBSITE, DOMAIN_NAME, Alert, Alert_model, } from '../common';

let nicheList = [];
let adtypeList = [];
let msgUsers = '';
let intervalId;
let options = [];
let item={};
export default class Websites extends Component{
  constructor(props) {
    super(props);
    this.state = {
      categories:[],
      nicheCategories:[],
      selectedCategories:[],
      selectedNicheCategories:[],
      files: [],
      errors: {},
      error:'',
      activeWebsites: [],
      inActiveWebsites: [],
      deleteId: null,
      activecurrentPage: this.props.location.state ? this.props.location.state.detail.activecurrentPage : 1 ,
      activenumOfPages: 0,
      inActivecurrentPage: this.props.location.state ? this.props.location.state.detail.inActivecurrentPage : 1,
      inActivenumOfPages: 0,
      vendors: [],
      selectedVendors: [],
      websiteSelectedVendors: [],
      selectedWebsites:[],
      timeStamp: '',
      vendorsList: [],
      websitesList:[],
      searchText: '',
      superuser: localStorage.getItem('SuperUser'),
      createErrors: {},
      categoryChoices: [],
      adtypeChoices: [],
      nicheText: '',
      nicheDropDown: false,
      adtypeDropDown: false,
      active: true,
      clientId: '',
      filter: false,
      filterVendors: [],
      loading:false,
      adtypeText:'',
      restore:false,
      uploadfile:false,
      websitecount:'',
      permanentDelete:false,
      alertmessage:'',   
      sortvalue:'sortby',
      language:'',
      protocols:'',
      acceptLinkInsertion: '',
      linksType: '',
      acceptGambling: '',
      acceptThc: '',
      acceptAdultContent: '',
      acceptWeightloss: '',      
      permanentArticle: true,
      advancedFilters:false,
      // selectedCountries:'',
      // alexahigh:'',
      // alexalow:'',
      dalow:'',
      dahigh:'',
      palow:'',
      pahigh:'',
      urlow:'',
      urhigh:'',
      drlow:'',
      drhigh:'',
      ahref_low:'',
      ahref_high:'',
      domain_age:'',
      language:'',
      languages:[],
      // alexa_speed:'',
      moz_spam_score:'',
      protocol:false,
      // countries:[],
      pending_website_count:1,
      numberofrows:100,
      idtoggle:false,
      svalue:'',
      domaintoggle:false,
      vendorstoggle:false,
      ranktoggle:false,
      datoggle:false,
      patoggle:false,
      drtoggle:false,
      urtoggle:false,  
      count:0,
      blocked:'',
      openMenu:false,
      categoriesOpenMenu:false,
      categoriesFilterOpenMenu:false,
      nicheCategoriesFilterOpenMenu:false,
      csvOpenMenu:false,
    }
    this.websitesList = this.websitesList.bind(this);
    this.search = this.search.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateApi = this.updateApi.bind(this);
  }

  componentDidMount() {
    this.websitesList(1)
    this.websiteGet()
    this.getIabCategoriesData()
    // this.getVendors()
    this._websiteCount()
    intervalId = setInterval(()=>this._websiteCount(), 5000)
    if(this.props.location.state){
      this.setState({ 
        filter:this.props.location.state.detail.filter,
        selectedNicheCategories:this.props.location.state.detail.selectedNicheCategories,
        activecurrentPage:this.props.location.state.detail.activecurrentPage,
        inActivecurrentPage:this.props.location.state.detail.inActivecurrentPage,
        acceptLinkInsertion:this.props.location.state.detail.acceptLinkInsertion,
        linksType: this.props.location.state.detail.linksType,
        acceptGambling: this.props.location.state.detail.acceptGambling,
        acceptThc: this.props.location.state.detail.acceptThc,
        acceptAdultContent: this.props.location.state.detail.acceptAdultContent,
        acceptWeightloss: this.props.location.state.detail.acceptWeightloss,
        selectedCategories: this.props.location.state.detail.selectedCategories,
        language: this.props.location.state.detail.language,
        protocol: this.props.location.state.detail.protocol,
        searchText: this.props.location.state.detail.searchText,
        filterVendors: this.props.location.state.detail.filterVendors,
        // selectedCountries:this.props.location.state.detail.selectedCountries,
        // alexahigh:this.props.location.state.detail.alexahigh,
        // alexalow:this.props.location.state.detail.alexalow,
        dalow:this.props.location.state.detail.dalow,
        dahigh:this.props.location.state.detail.dahigh,
        palow:this.props.location.state.detail.palow,
        pahigh:this.props.location.state.detail.pahigh,
        urlow:this.props.location.state.detail.urlow,
        urhigh:this.props.location.state.detail.urhigh,
        drlow:this.props.location.state.detail.drlow,
        drhigh:this.props.location.state.detail.drhigh,
        ahref_low:this.props.location.state.detail.ahref_low,
        ahref_high:this.props.location.state.detail.ahref_high,
        domain_age:this.props.location.state.detail.domain_age,
        language:this.props.location.state.detail.language,
        // alexa_speed:this.props.location.state.detail.alexa_speed,
        moz_spam_score:this.props.location.state.detail.moz_spam_score,      
      }, this.websitesFilter)      
    }
  }

  componentWillUnmount() {
    clearInterval(intervalId);
  }  

  removeModalErrors(){
    document.getElementById('form1').reset()
    this.setState({createErrors:'', websiteSelectedVendors:'', adtypeText:'', nicheText:''})
  }

  updateApi(id){
    fetch_get(`${WEBSITE}${id}/update-from-api/`)
    .then((response) => {
      if(!response.error){
        alert("successfully updated Api")
      } 
    })
    .catch((error) => {
    });
  }

  updateWebsite(value=this.state.sortvalue,page1=this.state.activecurrentPage, page2=this.state.inActivecurrentPage){
    let formData = new FormData()
    if (value == 'Domain'){
      formData.append("sort_by_domain",true)
    }
    // if (value == 'Alexa Rank'){
    //   formData.append("sort_by_alexa",true)     
    // }
    if(value == 'sortby'){
      formData.append('page1',1)
      formData.append('page2',1)      
      this.setState({activecurrentPage:1,inActivecurrentPage:1})
    }
    else{
      formData.append('page1',this.state.activecurrentPage)
      formData.append('page2',this.state.inActivecurrentPage)
    } 
    fetch_post(`${WEBSITES}list/?page1=${page1}&page2=${page2}`,formData)
    .then((response) => {
      if(!response.error) {
        // alert("Website restored successfully")
        this.setState({
          activeWebsites: response.active_websites, 
          inActiveWebsites: response.inactive_websites,
          activenumOfPages: response.active_num_pages,
          inActivenumOfPages: response.inactive_num_pages,
        })
      }      
    })
  }

  changeStatus(id) {
    fetch_get(`${WEBSITE}${id}/status/`) 
    .then((response) => {
      if(!response.error) {
        // alert("Website restored successfully")
        this.setState({restore:true,permanentDelete:false})
        this.websitesList()
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  permanentDelete(id){
    let formData = new FormData()
    formData.append('permanent_delete',true)
    fetch_post(`${WEBSITE}${id}/delete/`, formData)
    .then((response) => {
      if(response.error){
        // alert(response.message)
        this.setState({permanentDelete:true, restore:true, alertmessage:response.message})
        this.websitesList()
      }
      else{
        this.setState({permanentDelete:true, restore:true, alertmessage:response.message})
        this.websitesList()        
      }
    })
  }  

  /*getVendors() {
    fetch_get( UPLOADCSV)
    .then((response) => {
      console.log("res",response)
      if(!response.error) {
        let vendors = []
        response.vendors_list.map((vendor) => 
          vendors.push({ label: vendor.username, value: vendor.id })
        )
        this.setState({ vendorsList: vendors },this.websitesFilter)
      } 
    })
    .catch((error) => {
    });
  }*/

  _websiteCount() {
    if(this.state.pending_website_count > 0){
      fetch_get("/api/get_pending_website_data_count/")
      .then((response) => {
        if(!response.error) {
          this.setState({ pending_website_count: response.pending_websites_count })
        }
      })
      .catch((error) => {
      });
    }
  }  

  uploadCSV() {
    const data = new FormData();
    data.append('upload_csv', this.state.files[0]);
    // let vendors = [];
    // this.state.selectedVendors.length > 0 && this.state.selectedVendors.map((vendor) => 
    //   vendors.push(vendor.value)
    // )
    // if(vendors.length > 0) {
    //   data.append('vendors_list', JSON.stringify(vendors));
    // }
    fetch_post( UPLOADCSV, data)
    .then((response) => {
      if(!response.error) {
        this.props.history.push({pathname:'/admin/managewebsites/csvmap', state:{csv_fields:response.csv_fields, db_fields:response.db_fields, upload_csv:this.state.files[0]
          // ,vendors_list:JSON.stringify(vendors)
        }})
        this.setState({ files: [], selectedVendors: [], errors: {}, loading:false, websitecount:response.new_websites_count,})
        // msgUsers = response.vendors_websites_count_details.map((name)=>
        // name.user__username + " - " + name.website__count +"\n").join('')
        // alert("New Websites Count Is - " +response.new_websites_count+"\n"+msgUsers)
        // let a = document.getElementById('success')
        // a.click()
        this.websitesList()
      } else {
        if(response.errors) {
          this.setState({ errors: response.errors, loading:false })
        }
        //  else if(response.error_message) {
        //   this.setState({ errors: {vendor: response.error_message},loading:false })
        // }
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  handleChange(date) {
    this.setState({ timeStamp: date });
  }

  websitesList(page1=this.state.activecurrentPage, page2=this.state.inActivecurrentPage) {
    fetch_get(`${WEBSITES}list/?page1=${page1}&page2=${page2}`)
    .then((response) => {
      // let countries=[]
      // response.alexa_countries.map((country,i)=>{
      //   countries.push({ label: country, value:i })
      // })
      // let categories=[]
      // response.categories.map((category)=>{
      //   categories.push({ label: category.categories__name, value: category.categories__id })
      // })
      // let nicheCategories=[]
      // response.niche_categories.map((category)=>{
      //   nicheCategories.push({ label: category.niche__name, value: category.niche__id })
      // })
      this.setState({ 
        activeWebsites: response.active_websites, 
        inActiveWebsites: response.inactive_websites,
        activenumOfPages: response.active_num_pages,
        inActivenumOfPages: response.inactive_num_pages,
        // countries:countries,
        // categories:categories,
        // nicheCategories:nicheCategories,
        languages:response.languages,
        // activecurrentPage: response.page1,
        // inActivecurrentPage: response.page2,
        deleteId: null,
      })
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  // rows_count(){
  //   let formData = new FormData()
  //   this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
  //   formData.append('page1',this.state.activecurrentPage)
  //   formData.append('page2',this.state.inactivecurrentPage)    
  //   fetch_post(`${WEBSITES}list/`,formData)
  //   .then((response) => {
  //     if(!response.error){
  //       this.setState({
  //       activeWebsites: response.active_websites, 
  //       inActiveWebsites: response.inactive_websites,
  //       activenumOfPages: response.active_num_pages,
  //       inActivenumOfPages: response.inactive_num_pages,
  //       deleteId: null,
  //       })                   
  //     }
  //   })
  // }  

  clearFilter() {
    this.getIabCategoriesData()
    this.setState({ 
      acceptLinkInsertion: '',
      linksType: '',
      acceptGambling: '',
      acceptThc: '',
      acceptAdultContent: '',
      acceptWeightloss: '',
      selectedCategories: '',
      selectedNicheCategories:[],
      language: '',
      protocol: false,
      searchText: '',
      filterVendors: [],
      // selectedCountries:'',
      // alexahigh:'',
      // alexalow:'',
      dalow:'',
      dahigh:'',
      palow:'',
      pahigh:'',
      urlow:'',
      urhigh:'',
      drlow:'',
      drhigh:'',
      ahref_low:'',
      ahref_high:'',
      domain_age:'',
      language:'',
      // alexa_speed:'',
      moz_spam_score:'', 
      blocked:false,    
    }, this.websitesFilter)
  }

  websitesFilter(page1=this.state.activecurrentPage, page2=this.state.inActivecurrentPage, searchText=this.state.searchText) {
    // let check4 = this.refs['check4'].checked ? 'True' : 'False';
    let formData = new FormData()
    this.state.searchText && formData.append('domain', searchText)

    this.state.acceptLinkInsertion && formData.append('accept_link_insertion_in_existing_post', this.state.acceptLinkInsertion)
    this.state.linksType && formData.append('links_type',  this.state.linksType)
    this.state.acceptGambling && formData.append('accept_gambling', this.state.acceptGambling)
    this.state.acceptThc && formData.append('accept_thc', this.state.acceptThc)
    this.state.acceptAdultContent && formData.append('accept_adult', this.state.acceptAdultContent)
    this.state.acceptWeightloss && formData.append('accept_weight_loss', this.state.acceptWeightloss)
    this.state.dalow && formData.append('min_moz_da', this.state.dalow)
    this.state.dahigh && formData.append('max_moz_da', this.state.dahigh)
    this.state.palow && formData.append('min_moz_pa', this.state.palow)
    this.state.pahigh && formData.append('max_moz_pa', this.state.pahigh)
    this.state.urlow && formData.append('min_ur', this.state.urlow)
    this.state.urhigh && formData.append('max_ur', this.state.urhigh)  
    this.state.drlow && formData.append('min_dr', this.state.drlow)
    this.state.drhigh && formData.append('max_dr', this.state.drhigh)
    // this.state.alexalow && formData.append('min_alexa_rank', this.state.alexalow)
    // this.state.alexahigh && formData.append('max_alexa_rank', this.state.alexahigh)
    this.state.ahref_low && formData.append('min_ahrefs_rank', this.state.ahref_low)
    this.state.ahref_high && formData.append('max_ahrefs_rank', this.state.ahref_high) 
    this.state.domain_age && formData.append('domain_age', this.state.domain_age) 
    this.state.language && formData.append('language', this.state.language)   
    // this.state.alexa_speed && formData.append('alexa_speed', this.state.alexa_speed) 
    this.state.moz_spam_score && formData.append('moz_spam_score', this.state.moz_spam_score)
    this.state.protocol && formData.append('protocols','https')
    this.state.blocked && formData.append('is_blocked',true)
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)       
    let categories = [];
    // let countries = [];
    this.state.selectedCategories.length > 0 && this.state.selectedCategories.map((category) => 
      categories.push(category.value)
    )
    if(categories.length > 0) {
      formData.append('categories', JSON.stringify(categories));
    }
    let nicheCategories = [];
    this.state.selectedNicheCategories.length > 0 && this.state.selectedNicheCategories.map((category) => 
      nicheCategories.push(category.value)
    )
    if(nicheCategories.length > 0) {
      formData.append('niche_category', JSON.stringify(nicheCategories));
    }    

    // this.state.selectedCountries.length > 0 && this.state.selectedCountries.map((country) => 
    //   countries.push(country.label)
    // )
    // if(countries.length > 0) {
    //   formData.append('alexa_country',JSON.stringify(countries));
    // }    
    // this.state.selectedCategories && formData.append('categories', this.state.selectedCategories)
    this.state.language && formData.append('language', this.state.language)
    let users = []
    this.state.filterVendors.length > 0 && this.state.filterVendors.map((user) =>
      users.push(user.value)
    )
    if(users.length > 0) {
      formData.append('assigned_vendors_list', JSON.stringify(users))
    }
    formData.append('page1', page1)
    formData.append('page2', page2) 
    if(this.state.svalue == 'id'){
      if(this.state.idtoggle){
        formData.append('column_name_order','id')
      }
      else{
        formData.append('column_name_order','-id')
      }
    } 
    if(this.state.svalue == 'domain'){
      if(this.state.domaintoggle){
        formData.append('column_name_order','domain')
      }
      else{
        formData.append('column_name_order','-domain')
      }
    }
    if(this.state.svalue == 'vendors'){
      if(this.state.vendorstoggle){
        formData.append('column_name_order','vendors_count')
      }
      else{
        formData.append('column_name_order','-vendors_count')
      }
    }    
    // if(this.state.svalue == 'rank'){
    //   if(this.state.ranktoggle){
    //     formData.append('column_name_order','alexa_rank')
    //   }
    //   else{
    //     formData.append('column_name_order','-alexa_rank')
    //   }
    // } 
    if(this.state.svalue == 'da'){
      if(this.state.datoggle){
        formData.append('column_name_order','moz_da')
      }
      else{
        formData.append('column_name_order','-moz_da')
      }
    }
    if(this.state.svalue == 'pa'){
      if(this.state.patoggle){
        formData.append('column_name_order','moz_pa')
      }
      else{
        formData.append('column_name_order','-moz_pa')
      }
    } 
    if(this.state.svalue == 'dr'){
      if(this.state.drtoggle){
        formData.append('column_name_order','ahrefs_domain_rating_dr')
      }
      else{
        formData.append('column_name_order','-ahrefs_domain_rating_dr')
      }
    }
    if(this.state.svalue == 'ur'){
      if(this.state.urtoggle){
        formData.append('column_name_order','ahrefs_url_rating_ur')
      }
      else{
        formData.append('column_name_order','-ahrefs_url_rating_ur')
      }
    }                                       
    fetch_post(`${WEBSITES}list/ `, formData)
    .then((response) => {
      this.setState({ 
        activeWebsites: response.active_websites, 
        inActiveWebsites: response.inactive_websites,
        activenumOfPages: response.active_num_pages,
        inActivenumOfPages: response.inactive_num_pages,
        // activecurrentPage: response.page1,
        // inActivecurrentPage: response.page2,
        deleteId: null,
      },window.scroll(0,0))
      let model = document.getElementById("filterModel");
      model.click();
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  getCurrentPage(currentPage) {
    // if(this.state.sortvalue == 'sortby' || this.state.searchText !== ''  || this.state.language !== '' || this.state.protocols !== '' || this.state.selectedCategories.length > 0 ||
    //    this.state.acceptWeightloss !== '' || this.state.acceptGambling !== ''|| this.state.acceptThc !== ''||this.state.acceptAdultContent !== '' || this.state.acceptLinkInsertion !== ''){
    // let formData = new FormData()
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page1',currentPage)
    // formData.append('page2',currentPage)
    // fetch_post(`${WEBSITES}list/`,formData)
    // .then((response) => {
    //   if(!response.error){
    //     this.setState({
    //     activeWebsites: response.active_websites, 
    //     inActiveWebsites: response.inactive_websites,
    //     activenumOfPages: response.active_num_pages,
    //     inActivenumOfPages: response.inactive_num_pages,
    //     // activecurrentPage: response.page1,
    //     // inActivecurrentPage: response.page2,
    //     deleteId: null,
    //     })                   
    //   }
    // })          
      if(this.state.active){
        this.setState({activecurrentPage: currentPage},this.websitesFilter)
      }
      else{
        this.setState({ inActivecurrentPage:currentPage},this.websitesFilter)
      }
    // }
    // else if(this.state.sortvalue == 'Domain' || this.state.sortvalue == 'Alexa Rank' || this.state.searchText !== ''){
    //   if(this.state.active){
    //     this.setState({activecurrentPage: currentPage}, this.websitesFilter)
    //   }
    //   else{
    //     this.setState({ inActivecurrentPage:currentPage}, this.websitesFilter)
    //   }
    // }
    // else{
    //   if(this.state.active){
    //     this.setState({activecurrentPage: currentPage}, this.updateWebsite)
    //   }
    //   else{
    //     this.setState({ inActivecurrentPage:currentPage}, this.updateWebsite)
    //   }
    // }
  }

  search(searchText) {
    this.setState({ searchText }, this.websitesFilter(this.state.currentPage, searchText))
  }                       

  createWebsite() {
    const { websiteDetails, selectedUsers, nicheText, adtypeText } = this.state;
    let formData = new FormData()
    this.refs['cdomain'].value && formData.append('domain', this.refs['cdomain'].value.toLowerCase())
    formData.append('example_post',this.refs['cexamplepost'].value)
    formData.append('tat', this.refs['ctat'].value)
    adtypeText && formData.append('ad_type', this.state.adtypeText)
    this.refs['cprice'].value && formData.append('price', this.refs['cprice'].value)
    formData.append('special_notes', this.refs['cspecialnote'].value)
    formData.append('client_note', this.refs['cclient_note'].value)
    formData.append('minimum_word_count', this.refs['minimum_word_count1'].value)
    formData.append('maximum_number_of_links_in_post', this.refs['maximum_number_of_links_in_post1'].value)    
    formData.append('active', this.refs['ccheck1'].checked ? 'True' : 'False')
    formData.append('prepaid', this.refs['ccheck7'].checked ? 'True' : 'False')
    formData.append('permanent_article', this.refs['ccheck8'].checked ? 'True' : 'False')
    formData.append('writing_included', this.refs['ccheck9'].checked ? 'True' : 'False')
    formData.append('disclosure_tag', this.refs['ccheck10'].checked ? 'True' : 'False')
    formData.append('links_type', this.refs['clinkType'].value)
    let categories = [];
    this.state.nicheText.length > 0 && this.state.nicheText.map((category) => 
      categories.push(category.value)
    )
    if(categories.length > 0) {
      formData.append('categories', JSON.stringify(categories));
    }
    // nicheText && formData.append('categories', nicheText)
    let users = ''
    users = this.state.websiteSelectedVendors.value
    if(users !== '' && users !== undefined) {
      formData.append('vendor_id', users)
    }
    fetch_post(`${CREATE_WEBSITE}`, formData)
    .then((response) => {
      if(!response.error) {
        this.websitesList()
        this.setState({ createErrors: '' })
        // alert('website created successfully' )
        let a = document.getElementById("success");
        a.click();         
        let model = document.getElementById("createModel");
        model.click();
      } else {
        this.setState({ createErrors: response.errors })
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  websiteGet() {
    fetch_get(`${CREATE_WEBSITE}`)
    .then((response) => {
      adtypeList = response.ad_types;
      this.setState({adtypeChoices:adtypeList })
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  onSearch(e) {
    let value = e.target.value
    this.setState({ nicheText: value });
    let filteredValues = nicheList.filter(function (niche) {
      return (niche[0].toLowerCase().includes(value.toLowerCase()));
    });
    this.setState({ categoryChoices: filteredValues, nicheDropDown: (filteredValues.length) > 0 ? true : false })
  }

  onAdtypeSearch(e) {
    let value = e.target.value
    this.setState({ adtypeText: value });
    let filteredValues = adtypeList.filter(function (adtype) {
      return (adtype[0].toLowerCase().includes(value.toLowerCase()));
    });
    this.setState({ adtypeChoices: filteredValues, adtypeDropDown: (filteredValues.length) > 0 ? true : false })
  }  

  rendorVendors(vendors) {
    let list1 = vendors.length > 4 ? vendors.slice(0,4) : vendors;
    return(
      list1.map((vendor) => 
        <a className="avatar avatar-sm" key={vendor.id}>
          <img alt="" src={DOMAIN_NAME + vendor.profile_pic} title={vendor.username} className="rounded-circle"/>
        </a>  
      )
    )
  }

  rendorWebsites() {
    let carryProps = { filter:this.state.filter, 
      selectedNicheCategories:this.state.selectedNicheCategories,
      activecurrentPage:this.state.activecurrentPage,  
      inActivecurrentPage:this.state.inActivecurrentPage,  
      acceptLinkInsertion: this.state.acceptLinkInsertion,
      linksType: this.state.linksType,
      acceptGambling: this.state.acceptGambling,
      acceptThc: this.state.acceptThc,
      acceptAdultContent: this.state.acceptAdultContent,
      acceptWeightloss: this.state.acceptWeightloss,
      selectedCategories: this.state.selectedCategories,
      language: this.state.language,
      protocol: this.state.protocol,
      searchText: this.state.searchText,
      filterVendors: this.state.filterVendors,
      // selectedCountries:this.state.selectedCountries,
      // alexahigh:this.state.alexahigh,
      // alexalow:this.state.alexalow,
      dalow:this.state.dalow,
      dahigh:this.state.dahigh,
      palow:this.state.palow,
      pahigh:this.state.pahigh,
      urlow:this.state.urlow,
      urhigh:this.state.urhigh,
      drlow:this.state.drlow,
      drhigh:this.state.drhigh,
      ahref_low:this.state.ahref_low,
      ahref_high:this.state.ahref_high,
      domain_age:this.state.domain_age,
      language:this.state.language,
      // alexa_speed:this.state.alexa_speed,
      moz_spam_score:this.state.moz_spam_score, 
    }         
    if (this.state.activeWebsites && this.state.inActiveWebsites) {
      let websites = this.state.active ? this.state.activeWebsites : this.state.inActiveWebsites
      let page = this.state.active ? this.state.activecurrentPage : this.state.inActivecurrentPage
      return(
        <table className="table align-items-center table-flush">
          <thead className="thead-light">
            <tr>
              <th width="5%" style={{cursor:'pointer'}} onClick={()=>this.setState({idtoggle:!this.state.idtoggle,svalue:'id'},this.websitesFilter)}>Unique Id <i className="fas fa-sort"></i></th>
              <th width="15%" style={{cursor:'pointer'}} onClick={()=>this.setState({domaintoggle:!this.state.domaintoggle, svalue:'domain'},this.websitesFilter)}>Domain <i className="fas fa-sort"></i></th>
              <th width="10%" style={{cursor:'pointer'}} onClick={()=>this.setState({vendorstoggle:!this.state.vendorstoggle,svalue:'vendors'},this.websitesFilter)}>Vendors <i className="fas fa-sort"></i></th>
              {/* <th width="5%" style={{cursor:'pointer'}} onClick={()=>this.setState({ranktoggle:!this.state.ranktoggle,svalue:'rank'},this.websitesFilter)}>Rank <i className="fas fa-sort"></i></th> */}
              <th width="5%" style={{cursor:'pointer'}} onClick={()=>this.setState({datoggle:!this.state.datoggle,svalue:'da'},this.websitesFilter)}>DA <i className="fas fa-sort"></i></th>
              <th width="5%" style={{cursor:'pointer'}} onClick={()=>this.setState({patoggle:!this.state.patoggle,svalue:'pa'},this.websitesFilter)}>PA <i className="fas fa-sort"></i></th>
              <th width="5%" style={{cursor:'pointer'}} onClick={()=>this.setState({drtoggle:!this.state.drtoggle,svalue:'dr'},this.websitesFilter)}>DR <i className="fas fa-sort"></i></th>
              <th width="5%" style={{cursor:'pointer'}} onClick={()=>this.setState({urtoggle:!this.state.urtoggle,svalue:'ur'},this.websitesFilter)}>UR <i className="fas fa-sort"></i></th>              
              <th width="10%">Actions</th>
            </tr>
          </thead>
          <tbody>
            {websites.length > 0 ? websites.map((website, index) => 
              website.is_blocked ?
              <tr style={{ cursor: 'pointer', backgroundColor:'red' }} key={index}>
                <th scope="row" onClick={() => this.props.history.push({pathname:`/admin/managewebsites/${website.id}/details`,state:{detail:carryProps}})}>{website.id}</th>
                <td className="overlap" onClick={() => this.props.history.push({pathname:`/admin/managewebsites/${website.id}/details`,state:{detail:carryProps}})}>{website.domain}</td>
                <td onClick={() => this.props.history.push({pathname:`/admin/managewebsites/${website.id}/details`,state:{detail:carryProps}})}>{website.assigned_vendors_details.vendors_count} </td>
                {/* <td onClick={() => this.props.history.push({pathname:`/admin/managewebsites/${website.id}/details`,state:{detail:carryProps}})}>{ website.alexa_rank}</td> */}
                <td onClick={() => this.props.history.push({pathname:`/admin/managewebsites/${website.id}/details`,state:{detail:carryProps}})}>{ website.moz_da}</td>
                <td onClick={() => this.props.history.push({pathname:`/admin/managewebsites/${website.id}/details`,state:{detail:carryProps}})}>{ website.moz_pa}</td>
                <td onClick={() => this.props.history.push({pathname:`/admin/managewebsites/${website.id}/details`,state:{detail:carryProps}})}>{ website.ahrefs_domain_rating_dr}</td>
                <td onClick={() => this.props.history.push({pathname:`/admin/managewebsites/${website.id}/details`,state:{detail:carryProps}})}>{ website.ahrefs_url_rating_ur}</td>

                {this.state.active ? 
                <td>
                  <Link to={{ pathname:`/admin/managewebsites/${website.id}/edit` }}>
                    <span style={{ paddingRight:20 }}><i style={{fontSize:17}}className="fa fa-edit"></i></span>
                  </Link>
                  <DeleteUser userRole="website" id={this.state.deleteId} updateList={this.websitesList} />
                  <span data-toggle="modal" data-target="#deleteModal" onClick={() => this.setState({ deleteId: website.id, restore: false })}>
                    <i style={{ fontSize:17, color: 'red' }} className="fa fa-trash"></i>
                  </span>
                  <span style={{ paddingLeft:30 }} >
                    <button className="update_api btn btn-primary" onClick={() => this.updateApi(website.id)} >Update Api </button>
                  </span> 
                </td> : 
                <td>
                  <Fragment>
                    <a data-toggle="modal" data-target="#alert" onClick={() => this.changeStatus(website.id)} >
                      <span style={{paddingLeft:20 }}><i className="fa fa-sync"></i></span>
                    </a>
                    {localStorage.getItem('SuperUser') === 'true' ? 
                    <a data-toggle="modal" data-target="#alert" onClick={() => this.permanentDelete(website.id)} >
                      <span style={{paddingLeft:20 }}><i className="fa fa-trash"></i></span>
                    </a> : null }  
                  </Fragment>              
                </td>
                 }
              </tr>:
              <tr style={{ cursor: 'pointer' }} key={index}>
                <th scope="row" onClick={() => this.props.history.push({pathname:`/admin/managewebsites/${website.id}/details`,state:{detail:carryProps}})}>{website.id}</th>
                <td className="overlap" onClick={() => this.props.history.push({pathname:`/admin/managewebsites/${website.id}/details`,state:{detail:carryProps}})}>{website.domain}</td>
                <td onClick={() => this.props.history.push({pathname:`/admin/managewebsites/${website.id}/details`,state:{detail:carryProps}})}>{website.assigned_vendors_details.vendors_count} </td>
                {/* <td onClick={() => this.props.history.push({pathname:`/admin/managewebsites/${website.id}/details`,state:{detail:carryProps}})}>{ website.alexa_rank}</td> */}
                <td onClick={() => this.props.history.push({pathname:`/admin/managewebsites/${website.id}/details`,state:{detail:carryProps}})}>{ website.moz_da}</td>
                <td onClick={() => this.props.history.push({pathname:`/admin/managewebsites/${website.id}/details`,state:{detail:carryProps}})}>{ website.moz_pa}</td>
                <td onClick={() => this.props.history.push({pathname:`/admin/managewebsites/${website.id}/details`,state:{detail:carryProps}})}>{ website.ahrefs_domain_rating_dr}</td>
                <td onClick={() => this.props.history.push({pathname:`/admin/managewebsites/${website.id}/details`,state:{detail:carryProps}})}>{ website.ahrefs_url_rating_ur}</td>

                {this.state.active ? 
                <td>
                  <Link to={{ pathname:`/admin/managewebsites/${website.id}/edit` }}>
                    <span style={{ paddingRight:20 }}><i style={{fontSize:17}}className="fa fa-edit"></i></span>
                  </Link>
                  <DeleteUser userRole="website" id={this.state.deleteId} updateList={this.websitesList} />
                  <span data-toggle="modal" data-target="#deleteModal" onClick={() => this.setState({ deleteId: website.id, restore: false })}>
                    <i style={{ fontSize:17, color: 'red' }} className="fa fa-trash"></i>
                  </span>
                  <span style={{ paddingLeft:30 }} >
                    <button className="update_api btn btn-primary" onClick={() => this.updateApi(website.id)} >Update Api </button>
                  </span>
                  <span style={{ paddingLeft:30 }} >
                    <button className="update_api btn btn-primary" onClick={() => {window.open(`${DOMAIN_NAME}/public/websites/?domain=${website.domain}&token=dsa987sadhjsad976faiubdbqe7dh7`)}} >Create Public Page </button>
                  </span>
                </td> : 
                <td>
                  <Fragment>
                    <a data-toggle="modal" data-target="#alert" onClick={() => this.changeStatus(website.id)} >
                      <span style={{paddingLeft:20 }}><i className="fa fa-sync"></i></span>
                    </a>
                    {localStorage.getItem('SuperUser') === 'true' ? 
                    <a data-toggle="modal" data-target="#alert" onClick={() => this.permanentDelete(website.id)} >
                      <span style={{paddingLeft:20 }}><i className="fa fa-trash"></i></span>
                    </a> : null }  
                  </Fragment>              
                </td>
                 }
              </tr>              
            )
            :
              <tr>
                <th scope="row">No Websites found</th>
              </tr>
            }
          </tbody>
        </table>
      )
    }
  }

  onSort(e) {
    if(this.state.searchText == '')
    this.setState({sortvalue:e.target.value},this.updateWebsite)
    else
    this.setState({sortvalue:e.target.value},this.websitesFilter)
  }

  getVendorsData(vendor){
    let formData = new FormData()
    formData.append('username', vendor)
    fetch_post('/api/get_vendors_data/',formData)
    .then((response) => {
      if(!response.error) {
        let vendors = [];
        response.vendors_data.map((vendor) =>
        vendors.push({ label: vendor.username, value: vendor.id })
        )
        this.setState({ vendorsList: vendors })

      }
    })
  }

  getCategoriesData(category){
    let formData = new FormData()
    formData.append('name', category)
    fetch_post('/api/get_websites_category_data/',formData)
    .then((response) => {
      if(!response.error) {
        let categories=[]
        response.categories.map((category)=>{
          categories.push({ label: category.categories__name, value: category.categories__id })
        })
        this.setState({ categories: categories })
      }
    })

  }

  getIabCategoriesData(){
    let parent = ''
    fetch_get('/api/niche-category/list/')
    .then((response) => {
      if(!response.error) {
        let niche_categories=[]
        response.categories_list.map((category)=>{
          // if(true){
            if(category.parent == null){
              parent = category.name
              options = []
              item = {}
            }
            if(category.parent == parent){
              options.push({ label:category.name,value:category.id })
            }
            else{
              item={
                label: <a onClick={()=>{this.onOptionsClick(category,response.categories_list)}}><h6 style={{color:'black',cursor:'pointer'}}>{category.name}</h6></a>,
                options: options
              }
              niche_categories.push(item)
            } 
          // }
          
        })
        this.setState({ nicheCategories: niche_categories })
      }
    })

  }

  onOptionsClick(category,categoryList){
      categoryList.map((obj)=>{
      if(category.name == obj.parent){
        let found = this.state.selectedNicheCategories.some(sc => sc.label == obj.name)
        if(!found){
          this.state.selectedNicheCategories.push({ label:obj.name,value:obj.id })
        }
      } 
    })
     let nicheCategories = this.state.nicheCategories.filter((e,i)=>{
      if(e.label.props.children.props.children !== category.name){
            return e
      }
    })
  this.setState({selectedNicheCategories:this.state.selectedNicheCategories,nicheCategories:nicheCategories})
  }  

  render(){
    return(
      <div className="main-content">
        <TopHeader search={this.search} isSearch={true} />
        { this.state.restore && this.state.permanentDelete ? <Alert msg={this.state.alertmessage}/> : this.state.restore ? <Alert msg={"Website restored Successfully"}/> :  <Alert msg={"Website Deleted Successfully"}/>}
        { this.state.uploadfile ? <Alert_model msg={ "New Websites Count Is - " + this.state.websitecount +"  " +  msgUsers}/>: <Alert_model msg={"Website Created Successfully"}/>}        
        <div className="header bg-gradient-primary  pb-9"></div>
        <div className="container-fluid mt--7">
          <div className="row main_list_container">
            <div className="col-lg-6">
              <div className="card shadow">
                <div className="card-header bg-white border-0">
                  <h3 className="mb-0">Upload File <span className="error-text">*</span>
                  </h3>
                </div>
                <div className="card-body">
                  <Dropzone onDrop={acceptedFiles =>
                    this.setState({ files: acceptedFiles })}>
                    {({getRootProps, getInputProps}) => (
                      <section>
                        <div {...getRootProps()} className="drag_drop shadow text-center">
                          <input {...getInputProps()} />
                          <p>{this.state.files.length > 0 ? this.state.files[0].name : 'Drop or Click to Upload CSV file'}</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  <div className="text-danger text-center">
                    {this.state.errors.file && this.state.errors.file}
                  </div>
                 
                  <div className="row mt-1 justify-content-center align-items-center">
                    {/* <div className="col-lg-9 mb-2">
                      <Select
                        value={this.state.selectedVendors}
                        onChange={(e) => this.setState({ selectedVendors: e, csvOpenMenu:false })}
                        onInputChange={(e)=>{if(e){this.setState({csvOpenMenu:true},this.getVendorsData(e))}
                        else{
                          this.setState({csvOpenMenu:false})
                        }
                        }}
                        isMulti
                        options={this.state.vendorsList}
                        // menuIsOpen={this.state.csvOpenMenu}
                        closeMenuOnSelect={false}
                      />
                    </div> */}
                    {/* <div className="error-text" style={{paddingRight:'1.8rem'}}>*</div>    */}
                      <div className="col-lg-2 mt-1">
                    <form className="form-inline justify-content-center">
                      { this.state.loading ?
                        <Fragment>
                          <div className="spinner task">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                          </div>
                        </Fragment>
                        :
                      <button type="button" className="btn btn-info" onClick={() => {this.setState({ loading: true, uploadfile:true }, this.uploadCSV() )} }>Upload</button>
                      }
                    </form>
                     {/* <div className="text-danger">
                      {this.state.errors.vendor && this.state.errors.vendor}
                    </div> */}
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 instructions_col">
              <div className="card shadow">
                <div className="card-header bg-white border-0">
                  <h3 className="mb-0">Instructions</h3>
                </div>
                <div className="card-body">
                  <ul className="instructions">
                    <li>Upload file should be in .csv format</li>
                    <li>You should upload file upto maximum of 100KB</li>
                    <li>Make sure you upload file with atleast 1 website details</li>
                    {this.state.pending_website_count !== 0 ? <li>{this.state.pending_website_count} websites in queue to be processed</li>:null}
                  </ul>
                </div>
              </div>
            </div>
          </div>
         
          <div className="row main_list_container">
          <div className="col-lg-12">
            <div className="col">
              <div className="card shadow website_detail_profile">
                <div className="card-header row bg-white border-0">
                  <h3 className="mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.websitesFilter)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="mb-0 col-md-4 p-0 d-flex">Websites
                  <div className="mt-1">
                   <label className="custom-toggle mb-0 ml-3">
                    <input type="checkbox"/> 
                    <span className="custom-toggle-slider" onClick={() => this.setState({ active: !this.state.active })}> {this.state.active ? 'Active' : 'Inactive'} </span>
                  </label>
                  </div>
                  </h3>
                 
                  <div className="col-md-6 p-0 website_filter">
                    <button type="button" className="btn" onClick={() => this.setState({ filter: !this.state.filter })}> <i className="fas fa-filter"></i></button>
                    <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal2" onClick={() => this.setState({ uploadfile: false })}>Create Website</button>
                  </div>
                </div>
                {this.state.filter &&
                  <div className="row">
                      <div className="col-lg-12">
                        <div className="filter_result p-3">
                      
                          <form>
                            <div className="row">
                              <div className="col-md-3">
                                <div className="form-group">
                                  <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Domain"
                                    value={this.state.searchText}
                                    onChange={(e) => this.setState({ searchText: e.target.value}, this.websitesFilter)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <Select 
                                    className="form-control"
                                    value={this.state.selectedCategories}
                                    onChange={(e) => this.setState({ selectedCategories: e, categoriesFilterOpenMenu:false},this.websitesFilter)}
                                    onInputChange={(e)=>{if(e){this.setState({categoriesFilterOpenMenu:true},this.getCategoriesData(e))}
                                    else{
                                      this.setState({categoriesFilterOpenMenu:false})
                                    }
                                  }}
                                    options={this.state.categories}
                                    // menuIsOpen={this.state.categoriesFilterOpenMenu}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    placeholder="Categories"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <Select 
                                    className="form-control"
                                    value={this.state.selectedNicheCategories}
                                    onChange={(e) => {if(e.length == 0){this.getIabCategoriesData()}; this.setState({ selectedNicheCategories: e,filtered_data:{...this.state.filtered_data,['niche_categories']:e}},this.websitesFilter)}}
                                    options={this.state.nicheCategories}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    placeholder="IAB Categories"
                                  />
                                </div>
                              </div>                              
                              <div className="col-md-3">
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    value={this.state.language}
                                    onChange={(e) => this.setState({ language: e.target.value },this.websitesFilter)}
                                  >
                                  <option value=""> Select Language </option>
                                  {this.state.languages.map((lang)=>
                                    <option value={lang}>{lang}</option>
                                  )}
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mar-bot-5">
                                  <label className="form-control-label" htmlFor="input-email"> Accept Gambling </label>
                                  <select 
                                    value={this.state.acceptGambling}
                                    onChange={(e) => this.setState({ acceptGambling: e.target.value}, this.websitesFilter)}
                                    className="form-control"
                                  >
                                    <option value="">select</option>
                                    <option value="True">True</option>
                                    <option value="False">False</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mb-1">
                                  <div className="form-group mar-bot-5">
                                    <label className="form-control-label" htmlFor="input-email"> Accept THC </label>
                                    <select 
                                      value={this.state.acceptThc}
                                      onChange={(e) => this.setState({ acceptThc: e.target.value}, this.websitesFilter)}
                                      className="form-control"
                                    >
                                      <option value="">select</option>
                                      <option value="True">True</option>
                                      <option value="False">False</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                                <div className="col-md-3">
                                  <div className="form-group mb-1">
                                    <div className="form-group mar-bot-5">
                                    <label className="form-control-label" htmlFor="input-email"> accept link insertion </label>
                                    <select 
                                      value={this.state.acceptLinkInsertion}
                                      onChange={(e) => this.setState({ acceptLinkInsertion: e.target.value}, this.websitesFilter)}
                                      className="form-control"
                                    >
                                      <option value="">select</option>
                                      <option value="True">True</option>
                                      <option value="False">False</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mb-1">
                                  <div className="form-group mar-bot-5">
                                    <label className="form-control-label" htmlFor="input-email"> Accept Adult Content </label>
                                    <select 
                                      value={this.state.acceptAdultContent}
                                      onChange={(e) => this.setState({ acceptAdultContent: e.target.value}, this.websitesFilter)}
                                      className="form-control"
                                    >
                                      <option value="">select</option>
                                      <option value="True">True</option>
                                      <option value="False">False</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group mb-1">
                                  <div className="form-group mar-bot-5">
                                    <label className="form-control-label" htmlFor="input-email"> accept weight loss </label>
                                    <select 
                                      value={this.state.acceptWeightloss}
                                      onChange={(e) => this.setState({ acceptWeightloss: e.target.value}, this.websitesFilter)}
                                      className="form-control"
                                    >
                                      <option value="">select</option>
                                      <option value="True">True</option>
                                      <option value="False">False</option>
                                    </select>
                                  </div>
                                </div>
                              </div>                             
                              <div className="col-md-9">
                              </div>
                              <div style={{ cursor: 'pointer'}}>
                                <div className="form-group">
                                 <span id="advanced" onClick={() => this.setState({ advancedFilters: !this.state.advancedFilters })}></span>
                                </div>
                              </div>
                              {this.state.advancedFilters &&
                                <Fragment>
                                  {/* <div className="col-md-3">
                                    <div className="form-group">
                                      <Select 
                                        className="form-control"
                                        isMulti
                                        value={this.state.selectedCountries}
                                        onChange={(e) => this.setState({ selectedCountries: e}, this.websitesFilter)}
                                        options={this.state.countries}
                                        placeholder="countries"
                                        closeMenuOnSelect={false}
                                      />
                                    </div>
                                  </div>                                   */}
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <input 
                                        type="number" 
                                        className="form-control" 
                                        placeholder="moz_spam_score"
                                        onChange={(e) => this.setState({ moz_spam_score: e.target.value }, this.websitesFilter)}
                                        value={this.state.moz_spam_score}
                                      />
                                    </div>
                                  </div>                                  
                                {/* <div className="col-md-3">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="Alexa rank (Min)"
                                      onChange={(e) => this.setState({ alexalow: e.target.value }, this.websitesFilter)}
                                      value={this.state.alexalow}
                                    />
                                  </div>
                                </div> */}
                                {/* <div className="col-md-3">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="Alexa rank (Max)"
                                      onChange={(e) => this.setState({ alexahigh: e.target.value }, this.websitesFilter)}
                                      value={this.state.alexahigh}
                                    />
                                  </div>
                                </div> */}
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="DA (Min)"
                                      onChange={(e) => this.setState({ dalow: e.target.value }, this.websitesFilter)}
                                      value={this.state.dalow}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="DA (Max)"
                                      onChange={(e) => this.setState({ dahigh: e.target.value }, this.websitesFilter)}
                                      value={this.state.dahigh}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="PA (Min)"
                                      onChange={(e) => this.setState({ palow: e.target.value }, this.websitesFilter)}
                                      value={this.state.palow}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="PA (Max)"
                                      onChange={(e) => this.setState({ pahigh: e.target.value }, this.websitesFilter)}
                                      value={this.state.pahigh}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="AHREF (Min)"
                                      onChange={(e) => this.setState({ ahref_low: e.target.value }, this.websitesFilter)}
                                      value={this.state.ahref_low}
                                    />
                                  </div>
                                </div> 
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="AHREF (Max)"
                                      onChange={(e) => this.setState({ ahref_high: e.target.value }, this.websitesFilter)}
                                      value={this.state.ahref_high}
                                    />
                                  </div>
                                </div>                                                               
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="UR (Min)"
                                      onChange={(e) => this.setState({ urlow: e.target.value }, this.websitesFilter)}
                                      value={this.state.urlow}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="UR (Max)"
                                      onChange={(e) => this.setState({ urhigh: e.target.value }, this.websitesFilter)}
                                      value={this.state.urhigh}
                                    />
                                  </div>
                                </div> 
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="DR (Min)"
                                      onChange={(e) => this.setState({ drlow: e.target.value }, this.websitesFilter)}
                                      value={this.state.drlow}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="email" 
                                      className="form-control" 
                                      placeholder="DR (Max)"
                                      onChange={(e) => this.setState({ drhigh: e.target.value }, this.websitesFilter)}
                                      value={this.state.drhigh}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="number" 
                                      className="form-control" 
                                      placeholder="domain_age"
                                      onChange={(e) => this.setState({ domain_age: e.target.value }, this.websitesFilter)}
                                      value={this.state.domain_age}
                                    />
                                  </div>
                                </div> 
                                {/* <div className="col-md-2">
                                  <div className="form-group">
                                    <input 
                                      type="number" 
                                      className="form-control" 
                                      placeholder="alexa_speed"
                                      onChange={(e) => this.setState({ alexa_speed: e.target.value }, this.websitesFilter)}
                                      value={this.state.alexa_speed}
                                    />
                                  </div>
                                </div>         */}
                                <div className="col-md-2">
                                  <div className="form-group mb-1">
                                    <div className="form-group mar-bot-5">
                                      <label className="form-control-label" htmlFor="input-email"> HTTPS   <input
                                        type="checkbox"
                                        id="http"
                                        checked={this.state.protocol}
                                        onChange={(e) => this.setState({ protocol: document.getElementById('http').checked}, this.websitesFilter)}
                                      /> </label>
                                    
                                    </div>
                                  </div>
                                </div> 
                                <div className="col-md-2">
                                  <div className="form-group mb-1">
                                    <div className="form-group mar-bot-5">
                                      <label className="form-control-label" htmlFor="input-email"> Blocked only <input
                                        type="checkbox"
                                        id="blocked"
                                        checked={this.state.blocked}
                                        onChange={(e) => this.setState({ blocked: document.getElementById('blocked').checked}, this.websitesFilter)}
                                      /> </label>
                                    
                                    </div>
                                  </div>
                                </div>                                                                                                   
                                </Fragment>
                              }                              
                            </div>
                          </form>
                            <div className="col-lg-12 text-center">
                              <button type="button" className="btn btn-primary botton_b1 mb-3" onClick={() => this.clearFilter()}>Clear</button>
                              <button type="button" className="btn btn-primary botton_b1 mb-3" onClick={() => document.getElementById('advanced').click()}>Advanced Filters</button>
                          </div>
                        </div>
                      </div>
                  </div>
                }
                <div className="modal fade" id="exampleModal2" tabIndex="-1" role="dialog" aria-labelledby="modal-form" aria-hidden="true">
                  <div className="modal-dialog col-md-12" role="document">
                    <div className="col-lg-12">
                      <div className="filter_result mt-5">
                       <h3 className="mb-4 col-md-12 text-center">Create Website</h3>
                       <div className="row justify-content-center">
                        </div>
                        <form id="form1">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label>Domain <span className="error-text">*</span></label>
                                <input 
                                  type="text" 
                                  className="form-control" 
                                  ref="cdomain"
                                />
                                <div className="text-danger">
                                  {this.state.createErrors.website_errors && this.state.createErrors.website_errors.domain}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group mar-bot-5">
                                <label className="form-control-label" htmlFor="input-email"> Ad type <span className="error-text">*</span> </label>
                                <div className="input-group mb-3 dropdown no-caret">
                                  <input 
                                    type="text" 
                                    className="form-control form-control-alternative" 
                                    placeholder="Select adtype" 
                                    aria-label="Select User" 
                                    aria-describedby="button-addon2" 
                                    value={this.state.adtypeText}
                                    onChange={this.onAdtypeSearch.bind(this)} 
                                  /> 
                                  <div className="input-group-append " onClick={() => this.setState({ adtypeDropDown: !this.state.adtypeDropDown })}>
                                    <button className="toggle btn bg-white" type="button" id="button-addon2"><i className="fas fa-angle-down"></i></button> 
                                  </div>
                                  {this.state.adtypeDropDown &&
                                    <ul className="menu website_detail_menu label_menu" style={{ display: 'block'}}>
                                      {this.state.adtypeChoices.length > 0 && this.state.adtypeChoices.map((adtype, index) => 
                                        <li key={index} className="submenu" onClick={() => this.setState({ adtypeText: adtype[1], nicheDropDown: false, adtypeDropDown:false })}> <a href className="orange"> {adtype[0]} </a></li>
                                      )}
                                    </ul>
                                  }
                                </div>
                                <div className="text-danger">
                                  {this.state.createErrors.user_website_errors && this.state.createErrors.user_website_errors.ad_type}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group mar-bot-5">
                                <label className="form-control-label" htmlFor="input-email"> Assigned vendor list <span className="error-text">*</span> </label>
                                <Select
                                  value={this.state.websiteSelectedVendors}
                                  onChange={(e) => this.setState({ websiteSelectedVendors: e, openMenu:false })}
                                  onInputChange={(e)=>{if(e){this.setState({openMenu:true},this.getVendorsData(e))}
                                  else{
                                    this.setState({openMenu:false})
                                  }
                                }}
                                  options={this.state.vendorsList}
                                  menuIsOpen={this.state.openMenu}
                                />
                                <div className="text-danger">
                                  {this.state.createErrors.user_website_errors && this.state.createErrors.user_website_errors.vendors_list}
                                </div>
                              </div>
                            </div>   
                            <div className="col-lg-3">
                              <div className="form-group mar-bot-5">
                                <label className="form-control-label" htmlFor="input-email"> Categories <span className="error-text">*</span></label>
                                <div className="input-group mb-3 dropdown no-caret">
                                <Select 
                                  className="form-control"
                                  isMulti
                                  value={this.state.nicheText}
                                  onChange={(e) => this.setState({ nicheText: e, categoriesOpenMenu:false})}
                                  onInputChange={(e)=>{if(e){this.setState({categoriesOpenMenu:true},this.getCategoriesData(e))}
                                  else{
                                    this.setState({categoriesOpenMenu:false})
                                  }
                                }}
                                  options={this.state.categories}
                                  // menuIsOpen={this.state.categoriesOpenMenu}
                                  closeMenuOnSelect={false}
                                />                                  
                                </div>
                                <div className="text-danger">
                                  {this.state.createErrors.website_errors && this.state.createErrors.website_errors.categories}
                                </div>
                              </div>
                            </div>                            
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label className="form-control-label" >Minimum word count</label>
                              <input type="number" ref="minimum_word_count1" className="form-control" />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="form-group">
                              <label className="form-control-label" >Maximum number of links</label>
                              <input type="number" ref="maximum_number_of_links_in_post1" className="form-control" />
                            </div>
                          </div>                                                                                                                                                                                
                            <div className="col-lg-3">
                              <div className="form-group mar-bot-5">
                                <label className="form-control-label" htmlFor="input-email">Links Type </label>
                                <select 
                                  ref="clinkType" 
                                  className="form-control"
                                >
                                  <option value="Dofollow">DoFollow</option>
                                  <option value="Nofollow">NoFollow</option>
                                  <option value="Sponsored">Sponsored</option>
                                  <option value="UGC">UGC</option>
                                </select>
                              </div>
                            </div>                                                                                 
                            <div className="col-md-2">
                              <div className="form-group">
                                <label>ExamplePost</label>
                                <input 
                                  type="text" 
                                  className="form-control" 
                                  ref="cexamplepost"
                                />
                              </div>
                            </div> 
                            <div className="col-md-2">
                              <div className="form-group">
                                <label>TAT</label>
                                <input 
                                  type="text" 
                                  className="form-control" 
                                  ref="ctat"
                                />
                              </div>
                            </div>                           
                            <div className="col-md-2">
                              <div className="form-group">
                                <label>Price <span className="error-text">*</span> </label>
                                <input 
                                  type="text" 
                                  className="form-control" 
                                  ref="cprice"
                                />
                                <div className="text-danger">
                                  {this.state.createErrors.user_website_errors && this.state.createErrors.user_website_errors.price}
                                </div>
                              </div>
                            </div>                                                        
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>Special Note</label>
                                <textarea 
                                  type="text" 
                                  rows="3"
                                  className="form-control special_notes"
                                  style={{fontWeight:"normal",fontSize:"small"}}
                                  ref="cspecialnote"
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>Client_note</label>
                                <textarea 
                                  type="text" 
                                  rows="3"
                                  className="form-control special_notes"
                                  style={{fontWeight:"normal",fontSize:"small"}}
                                  ref="cclient_note"
                                />
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="form-group">
                                <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                                  <input className="custom-control-input" id="ccheck1" ref="ccheck1" type="checkbox" checked/>
                                  <label className="custom-control-label" htmlFor="ccheck1">Active</label>
                                </div>
                              </div>
                            </div>                                                        
                            <div className="col-md-3">
                              <div className="form-group">
                                <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                                  <input className="custom-control-input" id="ccheck7" ref="ccheck7" type="checkbox" />
                                  <label className="custom-control-label" htmlFor="ccheck7">Prepaid</label>
                                </div>
                              </div>
                            </div> 
                            <div className="col-md-2">
                              <div className="form-group">
                                <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                                  <input className="custom-control-input" id="ccheck8" ref="ccheck8" type="checkbox" checked={this.state.permanentArticle} onChange={()=>{this.setState({permanentArticle: !this.state.permanentArticle})}}/>
                                  <label className="custom-control-label" htmlFor="ccheck8">Permanent Article</label>
                                </div>
                              </div>
                            </div>  
                            <div className="col-md-2">
                              <div className="form-group">
                                <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                                  <input className="custom-control-input" id="ccheck9" ref="ccheck9" type="checkbox" />
                                  <label className="custom-control-label" htmlFor="ccheck9">Writing Included</label>
                                </div>
                              </div>
                            </div>   
                            <div className="col-md-2">
                              <div className="form-group">
                                <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                                  <input className="custom-control-input" id="ccheck10" ref="ccheck10" type="checkbox" />
                                  <label className="custom-control-label" htmlFor="ccheck10">Disclosure tag</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row justify-content-center">
                            <span data-toggle="modal" data-target="#Alert_model" id="success"></span>
                            <button type="button" className="btn btn-info" onClick={() => this.createWebsite()}>Create</button>
                            <button type="button" className="btn btn-default" id="createModel" data-dismiss="modal" onClick={this.removeModalErrors.bind(this)}>Cancel</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  {this.rendorWebsites()}
                </div>
                <div className="card-footer py-4">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-end mb-0">
                      {Number(this.state.active ? this.state.activenumOfPages : this.state.inActivenumOfPages) > 1 &&
                        <Pagination
                          currentPage={this.state.active ? this.state.activecurrentPage : this.state.inActivecurrentPage}
                          totalPages={this.state.active ? this.state.activenumOfPages : this.state.inActivenumOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      }
                    </ul>
                  </nav>
                </div>
              </div>
              <br />              
            </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}