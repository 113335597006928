import React, { Component } from 'react';
export class Alert extends Component{
  constructor(props) {
    super(props);
    this.state = {
      message:''
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      message:nextProps.msg
    })

  }
 
  render() {
    return (
      <div className="modal fade" id="alert" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
            </div>
            <div className="modal-body">
            <center>{this.state.message}</center>
            </div>
            <div className="modal-footer">
            {this.props.ChangePassword === 'true' && localStorage.getItem('role') === 'vendors' ? 
              <button type="button" className="btn btn-secondary" data-dismiss="modal" id="Alert_model" onClick={() => this.props.history.push('/vendors/signin')} >Close</button>:
              this.props.ChangePassword === 'true' && localStorage.getItem('role') === 'admin' ?
              <button type="button" className="btn btn-secondary" data-dismiss="modal" id="Alert_model" onClick={() => this.props.history.push('/admin/signin')} >Close</button>:
              this.props.ChangePassword === 'true' && localStorage.getItem('role') === 'creator' ?
              <button type="button" className="btn btn-secondary" data-dismiss="modal" id="Alert_model" onClick={() => this.props.history.push('/creator/signin')} >Close</button>:
              this.props.ChangePassword === 'true' || this.props.forgotpassword === 'true' || this.props.resetpassword === 'true' ?
              <button type="button" className="btn btn-secondary" data-dismiss="modal" id="Alert_model" onClick={() => this.props.history.push('/signin')} >Close</button>:
              <button type="button" className="btn btn-secondary" data-dismiss="modal" id="alert">Close</button>
            }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
