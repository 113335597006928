import React, { Component } from 'react';
import moment from 'moment';
import TopHeader from '../common/TopHeader';
import { DOMAIN_NAME, WEBSITE, USERWEBSITE, fetch_get,Alert_model, Alert, fetch_post } from '../common';
import Alert_selfmodel from '../common/Alert_selfmodel';
import BulkConfirmationModal from './BulkConfirmationModal';
import ProjectSingleOrder from './ProjectSingleOrder';


export default class ProjectDetails extends Component{
    constructor(props) {
    super(props);
    this.state = {
      errors: {},
      projectDetails: [],
      projects:[],
      opportunities:[],
      not_ordered:[],
      ordersList1:[],
      ordersList:[],
      orders:[],
      wid:''

    }
  this.uncheckwebsites = this.uncheckwebsites.bind(this)
  this.updatewebsites = this.updatewebsites.bind(this)  
  }

  componentDidMount() {
    this.projectDetails()
  }

  uncheckwebsites(){
    this.setState({ ordersList:[], ordersList1:[], websitesIds:[],price:[]})
    this.projectDetails()
  }

  updatewebsites(){
    this.projectDetails()
  }


  projectDetails(){
    fetch_get(`/api/project/${this.props.match.params.id}/details/ `)
    .then((response) => {
      this.setState({ projectDetails: response.project_serializer, projects:response.projects, opportunities:response.suggested_post_opportunities,not_ordered:response.not_ordered_websites, orders:response.orders })
    })
    .catch((error) => {
      this.setState({ error });
    });    
  }

  updateSingleWebsite(websiteId,domain,price,ad_type){
    let orderData1 = {}
    orderData1['websiteId'] = websiteId
    orderData1['domain'] = domain
    orderData1['price'] = price
    orderData1['ad_type'] = ad_type
    orderData1['total'] = price 
      this.setState({ websitesIds: [ parseInt(websiteId)], price:[price], ordersList1:[ orderData1] })
      // let b = document.getElementById('confirmationsinglemodal')
      // b.click()
    if(websiteId) {
      let model = document.getElementById("projectsinglemodal");
      model.click();
    }          
  }

  checkOrder(websiteId=null, price=null) {    
    let model = document.getElementById("cart");
    model.click();
  }

  addToWebsites(id){
    let formData = new FormData()
    formData.append('user_website_id', id)
    fetch_post(`/api/project/${this.props.match.params.id}/details/ `, formData)
    .then((response) => {
      if(!response.error) {
        this.setState({ projectDetails: response.project_serializer, projects:response.projects, opportunities:response.suggested_post_opportunities,not_ordered:response.not_ordered_websites, orders:response.orders })
        this.projectDetails()
        alert("added to websites")
      }
    })
    .catch((error) => {
      this.setState({ error });
    });

  }

  ignoreWebsite(id){
    let formData = new FormData()
    formData.append('remove_website_id', id)
    fetch_post(`/api/project/${this.props.match.params.id}/details/ `, formData)
    .then((response) => {
      if(!response.error) {
        this.setState({ projectDetails: response.project_serializer, projects:response.projects, opportunities:response.suggested_post_opportunities,not_ordered:response.not_ordered_websites, orders:response.orders })
        this.projectDetails()
        alert("successfully removed website")
      }
    })
    .catch((error) => {
      this.setState({ error });
    });

  }

  ignoreSuggestedWebsite(id,value){
    let formData = new FormData()
    if(value == 'opportunities'){
      formData.append('remove_suggested_website_id', id)
    }
    if(value == 'not_ordered'){
      formData.append('remove_website_id', id)
    }
    if(value == 'orders'){
      formData.append('remove_order_id', id)
    }        
    fetch_post(`/api/project/${this.props.match.params.id}/details/ `, formData)
    .then((response) => {
      if(!response.error) {
        this.setState({ projectDetails: response.project_serializer, projects:response.projects, opportunities:response.suggested_post_opportunities,not_ordered:response.not_ordered_websites, orders:response.orders })
        this.projectDetails()
        if(value == 'opportunities'){
          alert("successfully removed suggestedwebsite")
        }
        if(value == 'not_ordered'){
          alert('successfully removed NotOrderedWebsite')
        }
        if(value == 'orders'){
          alert('successfully removed Order')
        }

      }
    })
    .catch((error) => {
      this.setState({ error });
    });

  }

  // beforeAdd(id){
  //   let b = document.getElementById('add_orders')
  //   this.setState({wid:id},b.click())
  // }

  // addToOrders(){
  //   let formData = new FormData()
  //   formData.append('add_website_existed_orders', this.state.wid)
  //   fetch_post(`/api/project/${this.props.match.params.id}/details/ `, formData)
  //   .then((response) => {
  //     if(!response.error) {
  //       let a = document.getElementById("deleteModel");
  //       a.click();
  //       this.setState({ projectDetails: response.project_serializer, projects:response.projects, opportunities:response.suggested_post_opportunities,not_ordered:response.not_ordered_websites, orders:response.orders })
  //       this.projectDetails()
  //       alert("successfully added orders")
  //     }
  //   })
  //   .catch((error) => {
  //     this.setState({ error });
  //   });    
  // }        

  render(){
    return(
      <div className="main-content">
        <TopHeader />
        <span data-toggle="modal" data-target="#exampleModal4" id="add_orders"></span>
        <BulkConfirmationModal orderlist={this.state.not_ordered} uncheckwebsites={this.uncheckwebsites} updatewebsites={this.updatewebsites} userrole={"client"} projectid={this.props.match.params.id}/>
        <ProjectSingleOrder  websiteids={this.state.websitesIds} prices={this.state.price} websitenames={this.state.domain} orderlist1={this.state.ordersList1} uncheckwebsites={this.uncheckwebsites} updatewebsites={this.updatewebsites} userrole={"client"} projectid={this.props.match.params.id}/>
        <Alert_model msg={'order created successfully'}/>
        {/*
        <div className="modal fade col-md-6 m-auto" id="exampleModal4" tabIndex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content m-auto">
              <div className="modal-header">
                <h5 className="modal-title" id="deleteModalLabel">Add All Existing Orders</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Are you Sure ? You want to add all exisisting orders ?
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="deleteModel">No</button>
                <button type="button" className="btn btn-primary" data-toggle="modal" onClick={()=>this.addToOrders()}>Yes</button>
              </div>
            </div>
          </div>
        </div>*/}         
        <div class="col-md-6 m-auto modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
              </div>
              <div class="modal-body">
              <center>Order Created Successfully</center>
              </div>
              <div class="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal" id="examplemodel1">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="col-md-12 m-auto instructions_col website_details">
            <div className="card shadow mb-6">
              <div className="card-header row bg-white bproject-0 mb-3">
                <h3 className="mb-0 col-md-6">Project: {this.state.projectDetails.name}</h3>
                <div className="col-md-6 text-right">                        
                </div>
              </div>
              <div className="row">
              
                 <span className="col-12">
                  <label className="order_value">Target url:</label>
                  <span>{this.state.projectDetails.target_url}</span>
                </span>
              </div>
              <div className="row">  
                <span className="col-12">
                  <label className="invoice_link">Keywords:</label>
                  {this.state.projectDetails.keywords ? this.state.projectDetails.keywords.map((d)=><span> <span style={{backgroundColor:"rgb(200,200,200)",borderRadius:"5px",padding:"0.25em",lineHeight:"1.7rem"}}>{d}</span> </span>):null}
                </span> 
              </div>
          <div className="row mt-5">
            <div className="col-xl-12">
              <div className="card shadow">
                <div className="card-header bg-white bproject-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Suggested websites</h3>
                      <p>We are checking the backlinks that point to domains listed in SERP for the provided keywords for this project and if we find a mathc the website is listed here.</p>
                    </div>
                    <div className="col text-right">
                    {this.state.opportunities.length > 10 &&
                      <button className="btn btn-primary" onClick={() => this.props.history.push(`/projects/${this.props.match.params.id}/suggestedwebsites`) }>See all </button>
                    }
                    </div>
                  </div>
                </div>
                <div className="table-responsive table_rate">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">domain</th>
                        <th scope="col">adtype</th>
                        <th scope="col">linktype</th>
                        <th scope="col">price</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.opportunities ? this.state.opportunities.slice(0,10).map((websites, index) => 
                      <tr style={{ cursor: 'pointer' }} key={index}>
                        <td scope="row">
                          {websites.id}
                        </td>
                        <td onClick={() => window.open(`https://app.rankcastle.com/websites/${websites.id}/details`)}>
                          {websites.website.domain}
                        </td>
                        <td>
                          {websites.ad_type}
                        </td>
                        <td scope="row">
                          {websites.links_type}
                        </td>
                        <td>
                          {websites.sell_price}
                        </td>
                        <td>
                          <button className="update_api btn btn-primary" onClick={() => this.addToWebsites(websites.id)} >Order Later </button>
                          <button className="update_api btn btn-primary" onClick={() => this.ignoreSuggestedWebsite(websites.id,'opportunities')} >Ignore </button>
                        </td>                                                                    
                      </tr>
                      )
                      :
                        <tr>
                          <th scope="row">No records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-xl-12">
              <div className="card shadow">
                <div className="card-header bg-white bproject-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Websites Ready to Order</h3>
                    </div>
                    <div className="col text-right">
                    {this.state.not_ordered.length > 0 &&
                      <button type="button" className="btn btn-info" style={{backgroundColor:"#17a2b8"}} onClick={() => this.checkOrder()} >Bulk Order</button>
                    }
                    {this.state.not_ordered.length > 10 &&
                      <button className="btn btn-primary" onClick={() => this.props.history.push(`/projects/${this.props.match.params.id}/websites`) }>See all </button>
                    }
                    </div>
                  </div>
                </div>
                <div className="table-responsive table_rate">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">domain</th>
                        <th scope="col">adtype</th>
                        <th scope="col">linktype</th>
                        <th scope="col">price</th>
                        <th scope="col">order</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.not_ordered.length > 0  ? this.state.not_ordered.slice(0,10).map((unorder, index) => 
                      <tr style={{ cursor: 'pointer' }} key={unorder.id}>
                        <td scope="row">
                          {unorder.id}
                        </td> 
                        <td scope="row" onClick={() => window.open(`https://app.rankcastle.com/websites/${unorder.id}/details`)} >
                          {unorder.website_details.domain}
                        </td>                        
                        <td>
                          {unorder.ad_type}
                        </td>
                        <td>
                          {unorder.links_type}
                        </td>
                        <td>
                          {unorder.sell_price}
                        </td>
                        <td>
                          <button type="button" className="btn btn-success btn-sm" onClick={() => this.updateSingleWebsite(unorder.id, unorder.website_details.domain, unorder.sell_price, unorder.ad_type)}>Orders Saved in this Project</button>
                          {/*{unorder.is_orders_exist ? <button className="update_api btn btn-primary" onClick={() => this.beforeAdd(unorder.id)} >Add orders to projects </button> : null}*/}
                        </td>
                        <td>
                          <button className="update_api btn btn-primary" onClick={() => this.ignoreSuggestedWebsite(unorder.id,'not_ordered')} >Remove from Project</button>
                        </td>                                                
                      </tr>
                      )
                      :
                        <tr>
                          <th scope="row">No records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <span data-toggle="modal" data-target="#ProjectSingleModal" id="projectsinglemodal"  data-backdrop="static" data-keyboard="false"/>
                <span data-toggle = "modal" data-target = "#BulkConfirmationModal" id="cart"  data-backdrop="static" data-keyboard="false"/>
                <span data-toggle="modal" data-target="#Alert_model" id="alt_modal"></span>
              </div>
            </div>
          </div>          
          <div className="row mt-5">
            <div className="col-xl-12 mb-5 mb-xl-0">
              <div className="card shadow">
                <div className="card-header bg-white bproject-0">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="mb-0">orders</h3>
                    </div>
                    <div className="col text-right">
                    {this.state.orders.length >10 &&
                      <button className="btn btn-primary" onClick={() => this.props.history.push(`/projects/${this.props.match.params.id}/orders`) }>See all </button>
                    }
                    </div>
                  </div>
                </div>
                <div className="table-responsive table_rate">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">s.no</th>
                        <th scope="col">orderid</th>
                        <th scope="col">created_on</th>
                        <th scope="col">websites</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.orders.length > 0 ? this.state.orders.slice(0,10).map((orders, index) => 
                      <tr style={{ cursor: 'pointer' }} key={orders.id}>
                        <td>
                          {index+1}
                        </td>
                        <td scope="row" onClick={() => window.open(`https://app.rankcastle.com/orders/${orders.order_id}/details`)}>
                          {orders.order_id}
                        </td>
                        <td>
                          {orders.created_on}
                        </td>
                          <td className="overlap" >
                            {orders.website_details.slice(0,5).map((d)=><span> <span style={{backgroundColor: "rgb(200,200,200)",borderRadius:"5px",padding:"0.25em",lineHeight:"1.7rem"}}>{d}</span> </span>)}
                          </td>
                          <td>
                            <button className="update_api btn btn-primary" onClick={() => this.ignoreSuggestedWebsite(orders.order_id,'orders')} >Remove </button>
                          </td>
                      </tr>
                      )
                      :
                        <tr>
                          <th scope="row">No records Found</th>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
            </div>
          </div>
        </div>  
      </div> 
    )
  }
}
