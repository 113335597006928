import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import download from "downloadjs";
import TopHeader from '../common/TopHeader';
import { ORDER, fetch_get, fetch_post, DOMAIN_NAME, USER } from '../common';
let token = ''
export default class InvoicesDetails extends Component{
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: [],
      orderId: '',
      clientinfo:'',
      ordervalue:'',
      paymentId: null,
      loading: false,
      redirectUrl: '',
      invoiceId:'',
      discount:'',
      totalamount:'',
      show: true,
    }
    this.redirectPage = React.createRef();
  }

  componentWillMount(){
    var url_string = window.location.href
    var url = new URL(url_string);
    var path = url_string.substring(0,url_string.indexOf('?'))
    let token = url.searchParams.get("autologin");
      if(token){
        localStorage.setItem('payment_page',path.split('rankcastle.com/')[1])
        let etoken = token.slice(0,-1)
        localStorage.removeItem('UserId')
        if(!(localStorage.getItem('role'))){
          localStorage.setItem('role','client');
          localStorage.setItem('UserToken',etoken);
        }
        this.getUser()
        this.setState({})
      }
  }

  getUser(){
    let formData = new FormData()
    formData.append('user_token', localStorage.getItem('UserToken'))
    fetch_post('/api/get_user/',formData)
    .then((response)=>{
      if(!response.error) {
        localStorage.setItem('UserId',response.user);
      }
    })

  }

  componentDidMount() {
    this.orderDetails()
  }

  payment(id) {
    fetch_get(`${USER}${id}/pay/`)
    .then((response) => {
      if(!response.error) {
        this.setState({ redirectUrl: response.redirect_url, loading: false })
      }
      this.redirectPage.current.click();
    })
    .catch((error) => {
      this.setState({ error });
    });
  }  

  orderDetails() {
    fetch_get(`${ORDER}/${this.props.match.params.id}/details/`)
    .then((response) => {
      if(!response.error) {
        this.setState({ orderDetails: response.order_details, 
                        orderId: response.order_details.order_id,
                        invoiceId: response.order_details.id,
                        clientinfo:response.order_details.created_by.username,
                        ordervalue:response.order_details.amount,
                        discount:response.order_details.discount_value,
                        totalAmount:response.order_details.total_amount,
                        is_percent:response.order_details.discount_is_percentage,
                      })
      }
      else{
        this.setState({show:false})
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  invoiceDownload(){
    fetch_get(`/api/invoice/${this.state.orderId}/download/`, true)
    .then((response) => response.blob())
    .then((response) => {
      download(response,'invoice.pdf')
    })
  }

  render(){
    if(this.state.show){
      return(
        <div className="main-content">
          <TopHeader showSearch={false}/>
          <div className="main-content">
            <div className="header bg-gradient-primary  pb-9">
            </div>
            <span style={{display:"none"}}> <a href={this.state.redirectUrl} ref={this.redirectPage}>abv</a></span>
            <div className="container-fluid mt--7">
              <div className="row mt-5 justify-content-center">
                <div className="col-lg-11">
                  <div className="card shadow  profile_card">
                    <div className=" row card-header marl">
                      <h3 className="mb-0 col-md-8">Order ID : <span className="order_id">{this.state.orderDetails.order_id}</span></h3>
                      <div className="col-md-4 text-right">
                      {
                        this.state.orderDetails.payment_status === 'Unpaid' ?
                        (this.state.loading && this.state.paymentId === this.state.invoiceId) ? 
                          <Fragment>
                            <div className="spinner task">
                              <div className="double-bounce1"></div>
                              <div className="double-bounce2"></div>
                            </div>
                          </Fragment>
                        :
                        <a 
                          style={{position:"relative",top:"-13px"}}
                          className="btn btn-primary btn-sm active" 
                          role="button" 
                          aria-pressed="true" 
                          onClick={() => {this.setState({ loading: true, paymentId: this.state.invoiceId }, this.payment(this.state.orderId) )} } 
                        >
                          Pay Now
                        </a> 
                        :
                        null
                      }                    
                        <button className="edit-website btn-primary mt-1 mr-2" style={{position:"relative",top:"-13px"}} onClick={() => this.props.history.goBack() }>
                        Back
                        </button>  
                        <span className="download">
                          <a className="icon download" >
                            <i style={{cursor:'pointer'}} className="ni ni-cloud-download-95" onClick={()=>this.invoiceDownload()}></i>
                          </a>  
                        </span> 
                      </div>                   
                    </div>
                    <div className="card-body">
                    <div className="card-header row no_border_bg">
                      <span className="col-md-6">
                        <label className="client_info">Name:</label>
                        <span>{this.state.clientinfo}</span>
                      </span>
                       <span className="col-md-6">
                        <label className="order_value">Ordervalue:</label>
                        <span>{this.state.ordervalue}</span>
                      </span>                                  
                    </div> 
                    <div className="card-header row no_border_bg">
                      {this.state.discount !== 0 ?
                        <span className="col-md-6">
                          <label className="client_info">Discount:</label>
                          <span>{this.state.is_percent ? this.state.discount + "%" : this.state.discount + "$"}</span>
                        </span>
                      :null}
                      {this.state.discount !== 0 ? 
                        <span className="col-md-6">
                          <label className="order_value">TotalAmount:</label>
                          <span>{this.state.totalAmount}</span>
                        </span> : null
                      }                                                                                                                                                                                                                                            
                    </div>                                       
                      <form className="pt-3">
                        <div className="">
                          <table className="table align-items-center table-flush">
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">S.No</th>
                                <th scope="col">Website</th>
                                <th scope="col">Ad_type</th> 
                                <th scope="col">Price</th> 
                                <th scope="col">Payment Status</th>
                                <th scope="col">Liveurl</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.orderDetails.user_order_details && this.state.orderDetails.user_order_details.map((website, index) => 
                                <tr key={website.user_website_details.website_id}>
                                  <td>{index + 1}</td>
                                  <td>{website.user_website_details.website}</td>
                                  <td>{website.user_website_details.ad_type}</td>
                                  <td>{website.user_website_details.website_vendors_list.length && website.user_website_details.website_vendors_list[0].promo_price > 0 ? website.user_website_details.website_vendors_list[0].promo_price : website.user_website_details.price}</td>
                                  <td>
                                    {this.state.orderDetails.payment_status}
                                  </td>
                                  <td>
                                    {website.post_url ? 
                                    <p style={{cursor:"pointer"}} className="posturl" onClick={()=> window.open(website.post_url)}>{website.post_url}</p>:
                                    "----"}
                                  </td>  
                                </tr>
                               )
                              }
                            </tbody>
                          </table>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>   
      )
    }
    else{
      return(
        <div className="main-content">
          <TopHeader showSearch={false} />
          <div className="main-content">
            <div className="header bg-gradient-primary  pb-9">
            </div>
            <div className="container-fluid mt--7">
              <div className="row mt-5 justify-content-center">
                <div className="col-lg-11">
                  <div className="card shadow  profile_card">
                    <div className="card-body">
                    <div className="card-header row no_border_bg">
                      <span className="col-md-4">
                      </span>
                       <span className="col-md-4">
                        <label className="order_value">You do not have permission to access this page</label>
                      </span>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );      
    }
  }
}

