import React, { Component, Fragment } from 'react';
import moment from 'moment';
import download from "downloadjs";
import { Link } from 'react-router-dom';
import TopHeader from '../common/TopHeader';
import { ORDER, fetch_get, fetch_post, DOMAIN_NAME } from '../common';
import LiveurlModel from './LiveurlModel';
import Select from 'react-select';

let websiteSelectedVendors = ''
  export default class InvoicesDetails extends Component{
    constructor(props) {
      super(props);
      this.state = {
        orderDetails: [],
        orderId: '',
        id:'',
        liveurl:'',
        clientinfo:'',
        ordercost:'',
        ordervalue:'',
        discount:'',
        totalamount:'',
      }
    }

  componentDidMount() {
    this.orderDetails()
  }

  componentWillReceiveProps(nextProps){
    if(this.props.location.state != nextProps.location.state){
      this.orderDetails()
    }
  }

  orderDetails() {
    fetch_get(`${ORDER}/${this.props.match.params.id}/details/ `)
    .then((response) => {
      if(!response.error) {
        this.setState({ orderDetails: response.order_details,
                        orderId: response.order_details.order_id,
                        clientinfo:response.order_details.created_by.username,
                        ordercost:response.order_details.order_cost,
                        discount:response.order_details.discount_value,
                        totalamount:response.order_details.total_amount,                        
                        ordervalue:response.order_details.amount,
                        is_percent:response.order_details.discount_is_percentage,
                      })
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  assignVendors(vId, wId) {
    websiteSelectedVendors = vId
    let formData = new FormData()
    formData.append('user_website', vId)
    formData.append('user_order_id', wId)
    fetch_post(`${ORDER}/${this.props.match.params.id}/details/ `, formData)
    .then((response) => {
      if(!response.error) {
        this.setState({ orderDetails: response.order_details, orderId: response.order_details.order_id })
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  invoiceDownload(){
    fetch_get(`/api/invoice/${this.state.orderId}/download/`, true)
    .then((response) => response.blob())
    .then((response) => {
      download(response,'invoice.pdf')
    })
  }

  render(){
    return(
      <div className="main-content">
        <TopHeader />
        <LiveurlModel orderid={this.state.orderId} id={this.state.id} history={this.props.history} liveurl={this.state.liveurl}/>
        <div className="main-content">
          <div className="header bg-gradient-primary  pb-9">
          </div>
          <div className="container-fluid mt--7">
            <div className="row mt-5 justify-content-center">
              <div className="col-lg-12"> 
                <div className="card shadow  profile_card order_list_detail">
                <div className="row card-header marl">
                  <h3 className="mb-0 col-md-8">Order ID : <span className="order_id"> {this.state.orderDetails.order_id}</span></h3>
                  <div className="col-md-4 text-right">
                  <button className="edit-website btn-info mt-1 mr-2" style={{position:"relative",top:"-13px"}} onClick={() => this.props.history.goBack() }>
                    Back
                  </button>
                  <span className="download">
                    <a className="icon download">
                      <i style={{cursor:'pointer'}} className="ni ni-cloud-download-95" onClick={()=>this.invoiceDownload()}></i>
                    </a>  
                  </span>                                    
                  </div>                   
                  </div>  
                  <div className="card-body">                   
                  <div className="card-header row no_border_bg">
                    <span className="col-md-4">
                      <label className="client_info">Name:</label>
                      <span>{this.state.clientinfo}</span>
                    </span>
                     <span className="col-md-4">
                      <label className="order_value">Ordervalue:</label>
                      <span>{this.state.ordervalue}</span>
                    </span>
                     <span className="col-md-4">
                      <label className="order_value">OrderCost:</label>
                      <span>{this.state.ordercost}</span>
                    </span>                    
                  </div>
                  <div className="card-header row no_border_bg">
                  {this.state.discount !== 0 ?  
                    <span className="col-md-4">
                      <label className="client_info">Discount:</label>
                      <span>{this.state.is_percent ? this.state.discount + "%" : this.state.discount + "$"}</span>
                    </span>
                    : null
                  }
                  {this.state.discount !== 0 ? 
                     <span className="col-md-4">
                      <label className="order_value">TotalAmount:</label>
                      <span>{this.state.totalamount}</span>
                    </span>
                    : null
                  }                
                  </div>                                                          
                    <form className="pt-3">
                      <div className="table-responsive">
                        <table className="table align-items-center table-flush">
                          <thead className="thead-light">
                            <tr>
                              <th>S.No</th>
                              <th>Website</th>
                              <th>Ad type</th> 
                              <th>Price</th> 
                              <th>Payment Status</th>
                                <th className="posturl">Live Url</th>
                            </tr>
                           </thead>       
                              <tbody>
                            {this.state.orderDetails.user_order_details && this.state.orderDetails.user_order_details.map((website,index) =>
                              <tr key={website.user_website_details.id}>
                                <td>
                                  {index + 1}
                                </td>
                                <td>
                                  {website.user_website_details.website}
                                </td>
                                <td>
                                  {website.user_website_details.ad_type ? website.user_website_details.ad_type : '--'}
                                </td>
                                <td>{website.user_website_details.website_vendors_list.length && website.user_website_details.website_vendors_list[0].promo_price > 0 ? website.user_website_details.website_vendors_list[0].promo_price : website.user_website_details.price}</td>
                                <td>
                                  {this.state.orderDetails.payment_status}
                                </td>
                                <td>
                                  {website.post_url ? 
                                  <p style={{cursor:"pointer"}} className="posturl" onClick={()=> window.open(website.post_url)}>{website.post_url}</p>:
                                  "----"}
                                </td>                                
                              </tr>
                             )
                            }
                          </tbody>
                        </table>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>   
    )
  }
}
