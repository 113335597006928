import React, { Component, Fragment } from 'react';
import TopHeader from '../common/TopHeader';
import DeleteUser from './DeleteUser';
import Select from 'react-select';
import { ORDER, fetch_get, fetch_post, Pagination } from '../common';

let orderStatus = ""
export default class Orders extends Component{
  constructor(props) {
    super(props);
    this.state = {
      ordersList: [],
      currentPage: 1,
      numOfPages: 0,
      orderid:'',
      order_id:'',
      order_status:'',
      filter: false, 
      createdUsersList:[], 
      createduser:'',
      numberofrows:100,
      idtoggle:false,
      svalue:'',
      ordertoggle:false,
      createdbytoggle:false,
      createdontoggle:false,
      websitecounttoggle:false,
      orderstatustoggle:false,
      paymentoggle:false,                
    }
    this.ordersList = this.ordersList.bind(this);
  }

  componentDidMount() {
    this.ordersList()
  }

  updateStatus(userOrderId, status) {
    let formData = new FormData()
    formData.append('order_id', userOrderId)
    formData.append('order_status', status)
    formData.append('page', this.state.currentPage)
    fetch_post(`${ORDER}s/list/`, formData)
    .then((response) => {
      if(!response.error) {
        orderStatus = response.order_status
        this.setState({ 
          ordersList: response.orders,
          numOfPages: response.num_pages,
           
        })
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  rendorStatus(status, userOrderId) {
    orderStatus = status;
    return(
      <td className="p-1">
        <div className="col-md-12 p-0">
          <div className="form-group mb-0">
          {orderStatus === 'Completed'|| orderStatus == 'Paid' ?
            <select disabled
              value={orderStatus}
              onChange={(e) => this.updateStatus(userOrderId, e.target.value)}
              className="form-control"
            >
              <option value="">Select</option>
              <option value="Paid">Paid</option>
              <option value="Pending">Pending</option>
              <option value="Completed">Completed</option>
              <option value="Archived">Archived</option>
            </select>
          :
            <select
              value={orderStatus}
              onChange={(e) => this.updateStatus(userOrderId, e.target.value)}
              className="form-control"
            >
              <option value="">Select</option>
              <option value="Pending">Pending</option>
              <option value="Completed">Completed</option>
              <option value="Paid">Paid</option>
              <option value="Invoiced">Invoiced</option>
              <option value="Archived">Archived</option>
            </select>
          }
          </div>
        </div>
      </td>
    )
  }

  ordersList(page=this.state.currentPage) {
    fetch_get(`${ORDER}s/list/?page=${page}&num_pages=${this.state.numberofrows}`)
    .then((response) => {
      if(!response.error) {
        let created_users=[]
        response.created_by_data.map((user)=>{
          created_users.push({label:user.created_by__username, value:user.created_by__id})
        })
        this.setState({ 
          ordersList: response.orders,
          numOfPages: response.num_pages,
          createdUsersList:created_users, 
        })
      } else {
      }
    })
    .catch((error) => {
      this.setState({ error });
    });
  }

  // rows_count(){
    // let formData = new FormData()
    // formData.append('page', this.state.currentPage)
    // this.state.order_id && formData.append('filter_order_id', this.state.order_id)
    // this.state.createdUser && formData.append('created_by', this.state.createdUser.value)
    // this.state.order_status && formData.append('order_status_filter', this.state.order_status)
    // this.state.payment_status && formData.append('payment_status', this.state.payment_status)
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // fetch_post(`${ORDER}s/list/`,formData)
    // .then((response) => {
    //   console.log("resssspppppp",response)
    //   if(!response.error){
    //     this.setState({
    //       ordersList: response.orders,
    //       numOfPages: response.num_pages,
    //       currentPage: response.page
    //       // createdUsersList:created_users,
    //     })                
    //   }
    // })
  //   this.orderFilter();
  // }

  orderFilter(page=this.state.currentPage){
    let formData = new FormData()
    formData.append('page', page)
    this.state.order_id && formData.append('filter_order_id', this.state.order_id)
    this.state.createdUser && formData.append('created_by', this.state.createdUser.value)
    this.state.order_status && formData.append('order_status_filter', this.state.order_status)
    this.state.payment_status && formData.append('payment_status', this.state.payment_status)
    this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    if(this.state.svalue == 'id'){
      if(this.state.idtoggle){
        formData.append('column_name_order','id')
      }
      else{
        formData.append('column_name_order','-id')
      }
    }
    if(this.state.svalue == 'order_id'){
      if(this.state.ordertoggle){
        formData.append('column_name_order','order_id')
      }
      else{
        formData.append('column_name_order','-order_id')
      }
    }
    if(this.state.svalue == 'createdby'){
      if(this.state.createdbytoggle){
        formData.append('column_name_order','created_by__username')
      }
      else{
        formData.append('column_name_order','-created_by__username')
      }
    } 
    if(this.state.svalue == 'createdon'){
      if(this.state.createdontoggle){
        formData.append('column_name_order','created_on')
      }
      else{
        formData.append('column_name_order','-created_on')
      }
    } 
    if(this.state.svalue == 'websitecount'){
      if(this.state.websitecounttoggle){
        formData.append('column_name_order','websites_count')
      }
      else{
        formData.append('column_name_order','-websites_count')
      }
    }
    if(this.state.svalue == 'orderstatus'){
      if(this.state.orderstatustoggle){
        formData.append('column_name_order','order_status')
      }
      else{
        formData.append('column_name_order','-order_status')
      }
    } 
    if(this.state.svalue == 'payment'){
      if(this.state.paymenttoggle){
        formData.append('column_name_order','payment_status')
      }
      else{
        formData.append('column_name_order','-payment_status')
      }
    }                           
    fetch_post(`${ORDER}s/list/`, formData)
    .then((response) => {
      if(!response.error) {
        this.setState({ 
          ordersList: response.orders,
          numOfPages: response.num_pages,
          currentPage: response.page
        },window.scroll(0,0))
      }
    })
    .catch((error) => {
      this.setState({ error });
    });        
  }

  clearStates(){
    this.setState({
      order_id:'',
      createdUser:'',
      order_status:'',
      payment_status:''
    },this.orderFilter)
  }

  getCurrentPage(currentPage) {
    // let formData = new FormData()
    // this.state.order_id && formData.append('filter_order_id', this.state.order_id)
    // this.state.createdUser && formData.append('created_by', this.state.createdUser.value)
    // this.state.order_status && formData.append('order_status_filter', this.state.order_status)
    // this.state.payment_status && formData.append('payment_status', this.state.payment_status)
    // this.state.numberofrows && formData.append('custom_num_pages',this.state.numberofrows)
    // formData.append('page',currentPage)
    // fetch_post(`${ORDER}s/list/`,formData)
    // .then((response) => {
    //   console.log("rm",response)
    //   if(!response.error){
    //     this.setState({
    //       ordersList: response.orders,
    //       numOfPages: response.num_pages,
    //       currentPage:currentPage
    //       // createdUsersList:created_users,
    //     })                
    //   }
    //   else{
        this.setState({currentPage},this.orderFilter)
        // c
    //   }
    // })     
  }

  render(){
    return(
      <div className="main-content">
        <TopHeader />
        <div className="header bg-gradient-primary  pb-9">
        </div>
        <div className="container-fluid mt--6">
          <div className="row main_header">
            <div className="col">
              <div className="card shadow">
                <div className="row card-header bg-white border-0">
                  <h3 className="pt-3 mb-0 col-md-2 d-flex">show
                  <div>
                    <label className="custom-toggle mb-0">
                    <select
                    placeholder="show"
                    value={this.state.numberofrows}
                    onChange={(e)=>this.setState({numberofrows:e.target.value},this.orderFilter)}
                    >
                    <option value="">select</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    </select>
                    </label>
                  </div>
                  </h3>                
                  <h3 className="pt-3 col-md-4 d-flex">Orders</h3>
                  <div className="col-md-6 website_filter">
                    <button type="button" className="btn" onClick={() => this.setState({ filter: !this.state.filter },this.orderFilter)}> <i className="fas fa-filter"></i></button>
                  </div>                  
                </div>
                  {this.state.filter &&
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-12 p-0">
                          <div className="filter_result">
                            <form>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <input 
                                      type="text" 
                                      className="form-control" 
                                      value={this.state.order_id}
                                      onChange={(e) => this.setState({ order_id: e.target.value }, this.orderFilter)}
                                      placeholder="orderid"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <Select
                                    value={this.state.createdUser}
                                    placeholder="Select Vendor" 
                                    onChange={(e) => this.setState({ createdUser: e }, this.orderFilter)}
                                    options={this.state.createdUsersList}
                                  />
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mar-bot-5">
                                    <select 
                                      className="form-control"
                                      onChange={(e) => this.setState({ order_status: e.target.value }, this.orderFilter)}
                                      value={this.state.order_status}
                                    >
                                    <option value="">OrderStatus</option>
                                      <option value="Pending">Pending</option>
                                      <option value="Completed">Completed</option>
                                      <option value="Paid">Paid</option>
                                      <option value="Invoiced">Invoiced</option>
                                      <option value="Archived">Archived</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group mar-bot-5">
                                    <select 
                                      className="form-control"
                                      onChange={(e) => this.setState({ payment_status: e.target.value }, this.orderFilter)}
                                      value={this.state.payment_status}
                                    >
                                    <option value="">PaymentStatus</option>
                                      <option value="Paid">YES</option>
                                      <option value="Unpaid">NO</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <div className="col-lg-12 text-center">
                                <button type="button" className="btn btn-primary botton_b1 mb-0" onClick={() => this.clearStates(true)}>Clear</button>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }                
                <div className="table-responsive">
                  <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({idtoggle:!this.state.idtoggle,svalue:'id'},this.orderFilter)}>Unique Id <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({ordertoggle:!this.state.ordertoggle,svalue:'order_id'},this.orderFilter)}>Order Id <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({createdbytoggle:!this.state.createdbytoggle,svalue:'createdby'},this.orderFilter)}>Created By <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({createdontoggle:!this.state.createdontoggle,svalue:'createdon'},this.orderFilter)}>Created On <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({websitecounttoggle:!this.state.websitecounttoggle,svalue:'websitecount'},this.orderFilter)}>Website Count <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({orderstatustoggle:!this.state.orderstatustoggle,svalue:'orderstatus'},this.orderFilter)}>Order Status <i className="fas fa-sort"></i></th>
                        <th scope="col" style={{cursor:'pointer'}} onClick={()=>this.setState({paymenttoggle:!this.state.paymenttoggle,svalue:'payment'},this.orderFilter)}>Payment Done <i className="fas fa-sort"></i></th>
                        <th scope="col">Websites</th>
                        {localStorage.getItem('role') === 'admin' ?
                          <th>Actions</th> :
                          null
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.ordersList.length > 0 ? this.state.ordersList.map((order, index) => 
                        <tr style={{ cursor: 'pointer' }} key={order.id} >
                          <td onClick={() => this.props.history.push(`/admin/orders/${order.order_id}/details`, { role: 'admin' })}>
                            <a href>{order.id}</a>
                          </td>
                          <td onClick={() => this.props.history.push(`/admin/orders/${order.order_id}/details`, { role: 'admin' })}>
                            <a href>{order.order_id}</a>
                          </td>
                          <td onClick={() => this.props.history.push(`/admin/orders/${order.order_id}/details`, { role: 'admin' })}>
                            <a href>{order.created_by.username}</a>
                          </td>
                          <td onClick={() => this.props.history.push(`/admin/orders/${order.order_id}/details`, { role: 'admin' })}>
                            <a href>{order.created_on}</a>
                          </td>
                          <td onClick={() => this.props.history.push(`/admin/orders/${order.order_id}/details`, { role: 'admin' })}>
                            <a href>{order.website_details.length }</a>
                          </td> 
                          {this.rendorStatus(order.order_status, order.order_id)}
                          <td className="text-center" onClick={() => this.props.history.push(`/admin/orders/${order.order_id}/details`, { role: 'admin' })}>
                            {order.payment_status === 'Paid' ?
                              <a href><i className="fas fa-check" style={{ color: 'green' }}></i></a>
                            :
                              <a href><i className="fas fa-times" style={{ color: 'red' }}></i></a>
                            }
                          </td>
                          <td className="overlap" onClick={() => this.props.history.push(`/admin/orders/${order.order_id}/details`, { role: 'admin' })}>
                            {order.website_details.slice(0,5).map((d)=><span> <span style={{backgroundColor:"rgb(200,200,200)",borderRadius:"5px",padding:"0.25em",lineHeight:"1.7rem"}}>{d}</span> </span>)}
                          </td>
                          <td>
                            {localStorage.getItem('role') === 'admin' ?
                            <Fragment>
                            <DeleteUser userRole="order" id={this.state.orderid}  updateList={this.ordersList} history={this.props.history} />
                            <span style={{ marginLeft:"25px" }} data-toggle="modal" data-target="#deleteModal" onClick={()=>this.setState({orderid:order.order_id})}>
                              <i className="fa fa-trash"></i>
                            </span>
                            </Fragment> :
                            null}
                          </td>
                        </tr>
                      )
                      :
                        <tr>
                          <td>No Records found</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div className="card-footer py-4">
                  <nav aria-label="...">
                    <ul className="pagination justify-content-end mb-0">
                      {Number(this.state.numOfPages) > 1 &&
                        <Pagination
                          currentPage={this.state.currentPage}
                          totalPages={this.state.numOfPages}
                          getCurrentPage={this.getCurrentPage.bind(this)}
                        />
                      }
                    </ul>
                  </nav>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>   
    )
  }
}